export async function refreshRoutesCache() {
  /// TODO This fix is for refreshing the browser cache for the beta (when moving old website to new website).
  // Because of the cache management between vercel and cloudflare, it causes an issue when we reset the cache for on a specific route (for example: /app/dashboard/profile).
  // Causing, after going back to this route :
  // A 301 (from disk cache) of the specific page (example: /app/dashboard/profile). Meaning that the browser does not fetch again this page.
  // Fetching file that is from the icash-landing with the prefix /app causing 404 NOT FOUND

  // This method aim to hard reset cache for all route of icash-dashboard when we do a redirection (old website to new).
  const urls = [
    "/dashboard",
    "/dashboard/profile",
    "/app/dashboard/profile",
    "/app/dashboard/profile/change-email",
    "/app/dashboard/profile/email-verification-sent",
    "/app/dashboard/wallet",
    "/app/dashboard/loyalty-program",
    "/app/dashboard/referral-program",
    "/app/dashboard/application-contract",
    "/app/dashboard/application-start",
    "/app/dashboard/instant-loan/active-loan",
    "/app/dashboard/instant-loan/cashback",
    "/app/dashboard/instant-loan/loan-history",
    "/app/dashboard/instant-loan/settings",
    "/app/login",
    "/app/signup",
    "/app/signup-completion"
  ];

  const fetchAllPages = () => {
    const requests = urls.map((url) => fetch(url, { cache: "no-cache" }));

    return Promise.all(requests);
  };

  try {
    await fetchAllPages();
  } catch (error) {
    console.warn(`Cannot refresh root cache.`);
  }
  //End of the reset cache method.
}
