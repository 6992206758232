"use client";
import { Modal, Button } from "@brainfinance/icash-component-library";
import { refreshRoutesCache } from "@helpers/refresh-cache";

export default function Error({ error, reset }: { error: Error; reset: () => void }) {
  const onClose = async () => {
    await refreshRoutesCache();
    window.location.href = window.location.href + "?nocache=" + Date.now();
  };

  return (
    <Modal
      header={<h2 className="title-caption">Something went wrong</h2>}
      footer={
        <div className="flex flex-col items-stretch w-[400px] sm:w-full">
          <div className="flex-1 p-[22px] caption-light">Reload the page please.</div>
          <Button className="w-full" appearance="primary" onClick={onClose}>
            Reload
          </Button>
        </div>
      }
      onClose={onClose}
    />
  );
}
